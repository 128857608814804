// tools
import { useLotto } from "../../App";

// ui
import { Skeleton } from "@mui/material";

// comp
import CountDown from "../common/countdown";
import BallSection from "./ballSection";

// img
import PowerBlurBg from "../../img/main/power-blur-bg.svg";
import MegaBlurBg from "../../img/main/mega-blur-bg.svg";
import PowerWhiteLogo from "../../img/common/power-white-logo.svg";
import MegaWhiteLogo from "../../img/common/mega-white-logo.svg";

const Game = () => {
  const { loading, lottery } = useLotto();

  // 단위 변환기
  const amountMaker = (amount: number) => {
    const inmil = Number((amount / 1000000).toFixed(1));
    let final = 0;

    if (inmil > 1000) {
      const inbil = inmil / 1000;
      final = Math.floor(inbil * 100) / 100;
    } else if (inmil > 0) {
      final = inmil;
    }

    return final;
  };

  const sortedLottery = lottery.sort((a, b) => (a.type === "PB" ? -1 : 1));

  return (
    <section className="game-area">
      {sortedLottery.map((item) => {
        const type =
          item.type === "MM" ? "mega" : item.type === "PB" ? "power" : "";
        return (
          <div
            className={`item ${type}`}
            key={item.type}
            onClick={() => (location.href = `/${type}`)}
          >
            <div className="info-area">
              <img
                src={`${type === "power" ? PowerBlurBg : MegaBlurBg}`}
                alt="bg-01"
                className="bg01"
              />
              <img
                src={`${type === "power" ? PowerBlurBg : MegaBlurBg}`}
                alt="bg-02"
                className="bg02"
              />
              <div className="logo-wrap">
                <img
                  src={`${type === "power" ? PowerWhiteLogo : MegaWhiteLogo}`}
                  alt="logo"
                  className="logo"
                />
              </div>

              {loading ? (
                <Skeleton
                  animation="wave"
                  variant="rounded"
                  style={{
                    width: "90%",
                    height: 60,
                    margin: "0 auto",
                  }}
                />
              ) : (
                <p className="prize">
                  {item.isDrawdate ? "" : <span className="unit">$</span>}
                  <span className="num">
                    {item.isDrawdate
                      ? "Pending"
                      : `${amountMaker(
                          item.jackpot * Number(item.exchange)
                        ).toLocaleString("au")}`}
                  </span>
                  <span className="unit">
                    {item.isDrawdate
                      ? ""
                      : item.jackpot * Number(item.exchange) >= 1000000000
                      ? "Billion"
                      : "Million"}
                  </span>
                </p>
              )}

              <p className="time">
                <CountDown date={item.auOrderTime} pending={item.isDrawdate} />
              </p>
              <div className="btn">
                <span>PLAY NOW</span>
              </div>
            </div>
            {/* results */}
            <BallSection type={type} original={item.type} loading={loading} />
          </div>
        );
      })}
    </section>
  );
};

export default Game;
