// tools
import { useState, useEffect } from "react";
import axios from "axios";
import User from "../../tools/userInfo";

// ui
import Pagination from "@mui/material/Pagination";
import { CircularProgress } from "@mui/material";

// components
import NoData from "../common/noData";

interface List {
  balance: number;
  cash: number;
  createdAt: string;
  description: string;
  idx: number;
  status: string;
}

const History = () => {
  const { api, token } = User();
  const [page, setPage] = useState({
    max: 1,
    current: 1,
  });
  const [list, setList] = useState<List[]>([]);
  const [loading, setLoading] = useState(true);

  const listAxios = (page: number) => {
    axios
      .post(
        `${api}/cash/usage/history`,
        {
          startDate: "",
          endDate: "",
          page,
        },
        {
          headers: {
            "ngrok-skip-browser-warning": "69420",
            Authorization: "bearer " + token,
          },
        }
      )
      .then(({ data }) => {
        const Data = data.data;

        setPage({
          max: Data.last_page,
          current: Data.current_page,
        });

        setLoading(false);

        setList(Data.data);
      });
  };

  useEffect(() => {
    if (token) {
      const { search } = location;

      const pageParams = search.replace("?", "").split("&")[1];

      if (pageParams) {
        const page = Number(pageParams.split("=")[1]);
        listAxios(page);
      } else {
        listAxios(1);
      }
    }
  }, []);

  return (
    <div className="history">
      <table>
        <tr>
          <th>Date</th>
          <th>Description</th>
          <th>Amount</th>
          <th>Balance</th>
        </tr>

        {loading ? (
          <tr>
            <td colSpan={4}>
              <CircularProgress />
            </td>
          </tr>
        ) : list.length ? (
          list.map((item) => (
            <tr key={item.idx}>
              <td>{new Date(item.createdAt.split(" ")[0]).toDateString()}</td>
              <td>{item.description}</td>
              <td className={item.status === "U" ? "plus" : "minus"}>
                {item.status === "U" ? "-" : "+"}$ {item.cash}
              </td>
              <td>$ {item.balance}</td>
            </tr>
          ))
        ) : (
          <tr>
            <td colSpan={4}>
              <NoData />
            </td>
          </tr>
        )}
      </table>

      <Pagination
        count={page.max}
        page={page.current}
        className="pagination"
        onChange={(_, value) =>
          (location.href = `/mypage?tab=history&page=${value}`)
        }
      />
    </div>
  );
};

export default History;
