import React from "react";

const VideoModal: React.FC<any> = ({ playVideo, src }) => {
  const modalStyle = {
    position: "fixed" as const,
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "rgba(0, 0, 0, 0.7)",
    zIndex: 50,
    cursor: "zoom-out",
  };

  const closeButtonStyle = {
    position: "absolute" as const,
    top: "0.75rem",
    right: "0.75rem",
    padding: "0.25rem 0.75rem",
    marginTop: "0.75rem",
    marginRight: "0.75rem",
    fontSize: "2rem",
    color: "white",
    backgroundColor: "black",
    borderRadius: "0.375rem",
    cursor: "pointer",
    animation: "pulse 1.5s infinite",
  };

  const iframeContainerStyle = {
    position: "relative" as const,
    display: "flex",
    justifyContent: "center",
    width: "100%",
    height: "100%",
  };

  const iframeStyle = {
    margin: "auto",
    boxShadow: "0 0.5rem 1rem rgba(0, 0, 0, 0.3)",
    border: "1px solid rgba(255,255,255,0.2)",
    borderRadius: "14px",
  };

  const pulseKeyframes = `
    @keyframes pulse {
      0%, 100% {
        opacity: 1;
      }
      50% {
        opacity: 0.75;
      }
    }
  `;

  return (
    <div style={modalStyle} onClick={playVideo}>
      <style>{pulseKeyframes}</style>
      <div style={iframeContainerStyle}>
        {/* <button style={closeButtonStyle} onClick={() => playVideo}>
          &times;
        </button> */}
        <iframe
          title="videoModal"
          style={iframeStyle}
          width="1360"
          height="720"
          src={`https://www.youtube.com/embed/${src}?autoplay=1`}
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
      </div>
    </div>
  );
};

export default VideoModal;
