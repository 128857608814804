import App from "../App";
import { createBrowserRouter } from "react-router-dom";

// pages
import Signup from "../pages/signUp";
import Mega from "../pages/mega";
import Power from "../pages/power";
import Result from "../pages/result";
import Privacy from "../pages/privacy";
import Terms from "../pages/terms";
import AboutUs from "../pages/aboutUs";
import ErrorPage from "../pages/errorPage";
import Google from "../pages/google";
import Ticket from "../pages/ticket";
import Main from "../pages/main";
import Stat from "../pages/stat";
import Shorts from "../pages/shorts";
import News from "../pages/news";
import Magazine from "../pages/magazine";
import EmailUs from "../pages/emailUs";
import Mypage from "../pages/mypage";
import Faq from "../pages/faq";

// comp
import MagaDetail from "../components/magazine/detail";

// DESIGN
import New from "../pages/DESIGN/New";
import New2 from "../pages/DESIGN/New2";

// routes setting
const routes = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    errorElement: <ErrorPage />,
    children: [
      {
        index: true,
        element: <Main />,
      },
      // --- 새 디자인 작업 영역 ---
      { path: "/new", element: <New /> },
      { path: "/new2", element: <New2 /> },

      // -----------------------
      // 기존 작업 영역
      { path: "/signup", element: <Signup /> },
      { path: "/welcome", element: <Signup /> },
      { path: "/about", element: <AboutUs /> },
      { path: "/mega", element: <Mega /> },
      { path: "/power", element: <Power /> },
      { path: "/result", element: <Result /> },
      { path: "/faq", element: <Faq /> },
      { path: "/stat", element: <Stat /> },
      { path: "/news", element: <News /> },
      { path: "/email", element: <EmailUs /> },
      { path: "/mypage", element: <Mypage /> },
      { path: "/privacy", element: <Privacy /> },
      { path: "/terms", element: <Terms /> },
      { path: "/auth/google", element: <Google /> },
      { path: "/ticket", element: <Ticket /> },
      { path: "/magazine", element: <Magazine /> },
      { path: "/magazine/detail", element: <MagaDetail /> },
      { path: "/magazine/shorts", element: <Shorts /> },
    ],
  },
]);

export default routes;
