// comp
import Wrapper from "../components/common/wrapper";

const Privacy = () => {
  return (
    <Wrapper>
      <div className="sub privacy">
        <div className="sub-top-area">
          <h3>Privacy</h3>
        </div>
        <section>
          <h5>RATIONALE</h5>
          <p>
            This Privacy Policy sets out the principles undertaken by Global
            Players Network Pty Ltd (The Company) in the management of personal
            information of individual visitors and registered players of the
            Lottery Cluster website operated by The Company. By using the
            Website, you agree to this Privacy Policy and the Terms and
            Conditions.
            <br />
            <br />
            The Company is committed to protecting the privacy of personal
            information of individual visitors and registered players of
            lotteries via the Website (Registered Players). We will only
            collect, use or disclose information in accordance with this Act and
            this Privacy Policy.
          </p>
        </section>
        <section>
          <h5>POLICY</h5>
          <h6>Collection of Personal Information</h6>
          <p>
            The Company will only collect information about a visitor to the
            Website where it is provided directly from that visitor to The
            Company (with the exception of non-identifiable click-stream and
            cookie data provided automatically via the Website). A visitor will
            be unable to use the Website to play any lotteries unless and until
            they become a Registered Player.
            <br />
            <br />
            A Registered Player is required to set up an online account on the
            Website (Online Account) and in doing so, will be required to
            provide their full name, address, date of birth, e-mail address, and
            mobile number. To fund the Online Account, a Registered Player will
            need to submit payment via credit or debit card, BPay or other
            methods made available. Credit and debit card details are not stored
            by The Company but are encrypted at the processing bank where The
            Company does not have access to them. A Registered Player may also
            need to provide proof of identity if required by The Company.
            <br />
            <br />
            If an individual does not provide the information necessary to set
            up the Online Account and become a Registered Player, they will not
            be able to participate in lotteries via the Website.
            <br />
            <br />
            The Company also collects information from individuals via publicly
            available sources including via communications from individuals on
            The Company's social media sites. The Company may have occasion to
            collect information about the individual from a third party, in
            accordance with and as allowed by the Act. For example:
            <br />
            <br />
            <ul>
              <li>
                Companies contracted by us to improve our products and services
                or to ensure quality assurance; or
              </li>
              <li>
                List brokerage and list owners in the rental or purchase of
                lists.
              </li>
            </ul>
            <br />
            Where applicable, records of individuals who do not set up an Online
            Account within a certain timeframe (i.e. 3 months after initial
            offer) will be deleted. A Registered Player may also close their
            Online Account at any time by changing their account status under
            'My Settings' or by contacting our Customer Service Team.
          </p>
          <h6>Cookies</h6>
          <p>
            A Cookie is a piece of information that our web server may send to
            your machine when you visit the Website. The Cookie is stored on
            your machine, but does not identify you or give us any information
            about you. A Cookie helps us to recognise you when you re-visit our
            Website and to co-ordinate your access to different pages on the
            Website. With most internet browsers, you can erase Cookies from
            your computer hard drive, block all Cookies, or receive a warning
            before a Cookie is stored.
          </p>
          <h6>Use and Disclosure</h6>
          <p className="f18"></p>
          <p className="f16">
            The Company will only use or disclose personal information for the
            purpose which was either specified or reasonably apparent at the
            time of collection. The Company may also use or disclose personal
            information for any other related purpose for which such information
            would reasonably be expected to be used, to the extent permitted by
            the Act.
            <br />
            <br />
            One of the essential uses for personal information is so that The
            Company can ensure that individuals are legally entitled to use our
            services in accordance with our Government licence and regulatory
            provisions. This includes ensuring that individuals are of majority
            age and are legally entitled to participate in our gaming services.
            <br />
            <br />
            Personal information is also used for the purposes of operating our
            Responsible Gaming Policy as referred to in the Terms and
            Conditions. As well as restricting players to the advised spending
            limits our aim is to prevent the gambling of our players from having
            a detrimental effect on their lifestyle and that of their families.
            Most importantly we use personal information to prevent minors from
            using our lottery services.
            <br />
            <br />
            The Company will also use personal information about an individual
            as reasonably necessary to:
            <br />
            <br />
          </p>
          <ul>
            <li>
              Set up and facilitate the Online Account for a Registered Player;
            </li>
            <li>
              Ensure the effective administration of lottery participation,
              recording expenditure and winnings, history of gameplay and
              outcomes, bonuses earned and expended and all detailed customer
              service records;
            </li>
            <li>
              Make contact for the purposes of alerting a Registered Player
              about upcoming lottery jackpots and relevant lottery draw
              outcomes;
            </li>
            <li>
              Consider applications, enquiries and approaches made by an
              individual to The Company including Registered Player support
              requests;
            </li>
            <li>Maintain a Registered Player's contact details;</li>
            <li>
              Fulfil The Company's legal obligations under applicable laws and
              rules including to satisfy any legal process, subpoena or
              governmental request;
            </li>
            <li>
              Contact an individual regarding comments, issues, concerns or
              statements made via The Company's social media sites;
            </li>
            <li>
              Enforce the Website Terms and Conditions including the
              investigations of possible violations of them;
            </li>
            <li>
              Identify, prevent or address fraudulent activity, security or
              technical issues;
            </li>
            <li>
              Protect the rights of The Company, its users or the public; and
            </li>
            <li>
              To conduct market research and sales and other marketing
              activities.
            </li>
          </ul>
          <p className="f18">Direct Marketing</p>
          <p className="f16">
            Where and as permitted by the Act, The Company may also use personal
            information to send marketing or promotional materials. Anyone can
            opt-out from receiving marketing notifications via email and SMS by
            changing the notification settings on the Website under 'My
            Account', by choosing to unsubscribe from such communications, or by
            contacting our Customer Service Team.
            <br />
            <br />
            The Company may disclose Registered Player information to:
          </p>
          <ul>
            <li>
              Third parties, such as service providers, engaged by The Company
              to perform functions on behalf of The Company such as ID
              verification, processing credit card payments, IT, security,
              finance, marketing, research, legal compliance and advertising;
            </li>
            <li>
              Law enforcement agencies and governments worldwide for security
              purposes and regulatory compliance.
            </li>
          </ul>
          <p className="f18">Security and Storage of Personal Information</p>
          <p className="f16">
            The Company will take reasonable steps to protect personal
            information collected and ensure such information is accurate and
            complete. Information is held on secure servers or within controlled
            environments (e.g. in The Company's offices, on hard drives or with
            selected third party service providers). Employees of The Company
            are required to maintain the confidentiality of any personal
            information held by The Company.
            <br />
            <br />
            With regard to International Credit Card regulations, The Company is
            governed by the Payment Card Industry Data Security Standard which,
            amongst other things, requires The Company to be compliant with
            required PCI procedures and standards for the protection of
            customers. This includes a periodic assessment program to undergo
            validation processes including independent tests and reporting.
            <br />
            <br />
            The Northern Territory Government also reviews and approves company
            security control systems.
          </p>
          <p className="f18">
            Access, Correction, Deletion and Accountability of Personal
            Information Retained
          </p>
          <p className="f16">
            Individuals have the right to seek access to and correction of their
            personal information held by The Company and to advise us of any
            inaccuracy. There are exceptions to these rights as set out in the
            Act, and access may be denied if such an exception applies.
            <br />
            <br />
            If an individual requests access or correction to information
            relating to the individual, the Company will ask the individual to
            verify their identity and specify the nature of their request. The
            Company may charge a fee to cover the costs of meeting the request,
            where permitted by the Act. Any request will be dealt with in a
            reasonable timeframe.
            <br />
            <br />
            Information may be obtained or corrected by referring to company
            contact details on the Contact Us page or at the conclusion of this
            policy.
            <br />
            <br />
            Customer Service Consultants are trained to confirm and update where
            necessary, contact details of individuals.
            <br />
            <br />
            Individuals may request the deletion of their personal information
            at any time by contacting the Company on the details set out below.
            Any request will be dealt with as swiftly as possible.
          </p>
          <p className="f18">Transfer of Personal Information Overseas</p>
          <p className="f16">
            The Company does not transfer personal information outside of
            Australia. However, in the event of a disaster situation, The
            Company will use a cloud-based disaster recovery site that is hosted
            outside of Australia and its database, including personal
            information stored therein, will transfer outside of Australia in
            that event.
          </p>
          <p className="f18">Changes to this Privacy Policy</p>
          <p className="f16">
            The Company may, as necessary, review and update this Privacy Policy
            due to changes in law, technology or business operations. All
            changes will be posted to this page, without notice and you should
            therefore check this page for updates from time to time. All
            personal information held by The Company will be governed by this
            Privacy Policy.
          </p>
        </section>
      </div>
    </Wrapper>
  );
};

export default Privacy;
