import html2canvas from "html2canvas";
import jsPDF from "jspdf";

export function generateReportPageToPdf(
  frame: HTMLElement | null,
  title: string
) {
  if (frame) {
    const frameCopy = frame.cloneNode(true);
    const root = document.getElementById("root");
    const printFrame = document.createElement("div");
    printFrame.id = "printFrame";
    const frameHeight = frame.scrollHeight;
    printFrame.setAttribute("style", "position:absolute; left:-99999999px");
    printFrame.append(frameCopy);
    root?.append(printFrame);
    html2canvas(printFrame, {
      width: window.innerWidth,
      height: frameHeight,
      useCORS: true, //외부 이미지 사용시에 해줘야함
    }).then(function (canvas) {
      const imgData = canvas.toDataURL("image/png");
      const imgWidth = 210;
      const pageHeight = imgWidth * 1.414;
      const imgHeight = (canvas.height * imgWidth) / canvas.width;
      let heightLeft = imgHeight;
      const doc = new jsPDF("p", "mm", "a4");
      let position = 0;
      doc.addImage(
        imgData,
        "PNG",
        0,
        position,
        imgWidth,
        imgHeight,
        "",
        "FAST"
      );
      heightLeft -= pageHeight;
      // 이미지가 파일의 페이지의 길이보가 긴 경우, 이미지를 끊어서 다른 페이지에 저장해야함
      while (heightLeft >= 0) {
        position = heightLeft - imgHeight;
        doc.addPage();
        doc.addImage(
          imgData,
          "PNG",
          0,
          position,
          imgWidth,
          imgHeight,
          "",
          "FAST"
        );
        heightLeft -= pageHeight;
      }
      doc.save(`${title}.pdf`);
      printFrame.remove();
      //subPage 의 frame을 파일로 저장하는 경우
      frame.classList.contains("subFrame") && frame.remove();
    });
  }
}
