const StatMenu = [
  {
    text: 'Frequency of Each Number',
    menu: 'frequency',
  },
  {
    text: 'Last Appearences of Each Number',
    menu: 'lastdrawn',
  },
  {
    text: 'Odd and Even Numbers',
    menu: 'oddeven',
  },
  {
    text: 'Consecutive Numbers',
    menu: 'consecutive',
  },
  {
    text: 'High and Low Numbers',
    menu: 'highlow',
  },
  {
    text: 'Lucky Number Patterns',
    menu: 'pattern',
  },
]

export default StatMenu
