// tools
import { useState } from "react";

export interface FaqProps {
  title: string;
  content: string;
}

const faqItems: FaqProps[] = [
  {
    title: "How do I contact The Lottery Cluster?",
    content: `You can call our local Customer Support team, email us at
cluster@mail.com.au, or chat with us on Live Chat or Facebook
Messenger. For all details, please visit our Contact Us page`,
  },
  {
    title:
      "I’ve received a call/email asking for money before you will release my winnings – is this a scam?",
    content: `Yes.
    In the event of a win The Lottery Cluster will never ask you for any money before releasing your winnings.
    Our advice would be for you to hang up if you receive any future calls, and if you can, block the originating number.
    Ignore any emails of a similar nature. DO NOT send them any money under any circumstances.`,
  },
  {
    title: "Where is The Lottery Cluster located?",
    content: `We are based right here in Australia with our operations centre in Darwin, NT.`,
  },
  {
    title: "Who can sign up with The Lottery Cluster?",
    content: `Yes. The Lottery Cluster is operated by Global Players Network Pty Ltd,
    and licensed and regulated by the Northern Territory Government of Australia.
    All of our lotteries are approved, conducted and drawn in the Northern Territory under the supervision of Licensing NT.
    Our processes and financials are audited annually as required by our Regulator.`,
  },
];

const Faq = () => {
  const [openIndex, setOpenIndex] = useState<number | null>(0);

  const toggleFaq = (index: number) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  return (
    <section className="faq-area">
      <div className="accordion">
        {faqItems.map((it, idx) => (
          <div
            className={openIndex === idx ? "item open" : "item"}
            key={idx}
            onClick={() => toggleFaq(idx)}
          >
            <div className="tit-wrap">
              <h4 className="tit">{it.title}</h4>
              <span className="material-symbols-rounded">
                keyboard_arrow_down
              </span>
            </div>
            {openIndex === idx && (
              <div className="txt-wrap">
                <p>{it.content}</p>
              </div>
            )}
          </div>
        ))}
      </div>
    </section>
  );
};

export default Faq;
