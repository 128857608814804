// tools
import { useState, useEffect, useRef } from "react";

// components
import Wrapper from "../components/common/wrapper";
import LastWinningResult from "../components/sub/lastWinningResult";

const Result = () => {
  const [menu, setMenu] = useState("");
  const [toggleDateLists, setToggleDateLists] = useState(false);

  useEffect(() => {
    // path get params
    const search = location.search;
    const split = search.replace("?", "").split("&");
    const tab = split[0].split("=")[1];

    if (tab) {
      // if click power -> tab:power -> setMenu("power")
      setMenu(tab);
    } else {
      // init = mega
      setMenu("mega");
    }
  }, []);

  return (
    <Wrapper>
      <div className="sub result">
        <div className="sub-top-area">
          <h3 className="tit">Result</h3>
          <p>Lottery results are updated around the clock</p>
        </div>

        <ul className="tab style01" style={{ marginBottom: "24px" }}>
          <li
            className={menu === "mega" ? "current" : ""}
            onClick={() => (location.href = `/result?type=mega&tab=${menu}`)}
          >
            Mega Millions
          </li>
          <li
            className={menu === "power" ? "current" : ""}
            onClick={() => (location.href = `/result?type=power&tab=${menu}`)}
          >
            Powerball
          </li>
        </ul>
        <LastWinningResult type={menu} />
      </div>
    </Wrapper>
  );
};

export default Result;
