const Division = [
  {
    white: 5,
    special: true,
  },
  {
    white: 5,
    special: false,
  },
  {
    white: 4,
    special: true,
  },
  {
    white: 4,
    special: false,
  },
  {
    white: 3,
    special: true,
  },
  {
    white: 3,
    special: false,
  },
  {
    white: 2,
    special: true,
  },
  {
    white: 1,
    special: true,
  },
  {
    white: 0,
    special: true,
  },
]

export default Division
