// tools
import { useEffect, useRef, useState } from "react";
import axios from "axios";
import User from "../../tools/userInfo";

interface SectionType {
  type: string;
  original: string;
  loading: boolean;
}

interface Result {
  date: string;
  numbers: string;
  draw: number;
}

const BallSection = ({ type, original, loading }: SectionType) => {
  const { api } = User();
  const [open, setOpen] = useState(false);
  const contRef = useRef<HTMLDivElement>(null);
  const btnRef = useRef<HTMLDivElement>(null);

  // Result drop down off
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        contRef.current &&
        !contRef.current.contains(event.target as Node) &&
        btnRef.current &&
        !btnRef.current.contains(event.target as Node)
      ) {
        setOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const [main, setMain] = useState<Result>({
    date: "",
    numbers: "",
    draw: 0,
  });
  const [sub, setSub] = useState<Result[]>([]);

  useEffect(() => {
    axios
      .get(`${api}/result/${original.toLowerCase()}`, {
        headers: {
          "ngrok-skip-browser-warning": "69420",
        },
      })
      .then(({ data }) => {
        if (data.message === "success") {
          const Data = data.data.data;

          setMain({
            date: Data[0].auDrawTime.split(" ")[0],
            numbers: Data[0].winningNumber,
            draw: Data[0].drawnum,
          });

          const copy = [];

          for (let i = 1; i < 5; i++) {
            const numbers = Data[i].winningNumber;
            const date = Data[i].auDrawTime.split(" ")[0];
            const draw = Data[i].drawnum;

            copy.push({ date, numbers, draw });
          }

          setSub(copy);
        }
      })
      .catch((response) => {});
  }, []);

  return (
    <div className="result-area">
      <div className="tit-wrap">
        <p className="tit">
          {type === "power" ? "Powerball" : "Megaball"}
          <span>&nbsp; Latest Results</span>
        </p>
        <div
          className={`btn ${open ? "active" : ""}`}
          // onClick={(e) => {
          //   e.stopPropagation();
          //   setOpen((prev) => !prev);
          // }}

          onClick={() => (window.location.href = `/result?tab=${type}`)}
          ref={btnRef}
        >
          View results
          <span className="material-symbols-rounded">chevron_right</span>
        </div>
      </div>

      <div className="result-wrap">
        <p className="date">{new Date(main.date).toDateString()}</p>
        <div className="ball-wrap">
          {main.numbers.split(",").map((num, index) => (
            <div
              className={`ball ${
                index === main.numbers.split(",").length - 1 ? type : ""
              }`}
              key={`${main.date}_${num}`}
            >
              {num}
            </div>
          ))}
        </div>
      </div>

      {/* View results */}
    </div>
  );
};

export default BallSection;
