import { useState } from "react";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import Tooltip from "@mui/material/Tooltip";
import Lottie from "lottie-react";

// img
import FavIco from "../../img/sub/favorite-ico.svg";
import FavIcoChk from "../../img/sub/favorite-ico-fill.svg";
import Lightning from "../../img/sub/lightning.json";

const New = () => {
  const [ballEditOpen, setBallEditOpen] = useState(false);
  const [isPicked, setIsPicked] = useState(false);
  const [fav, setFav] = useState(false);
  const [checked, setChecked] = useState(false);

  const handleChange = (event: any) => {
    setChecked(event.target.checked);
  };

  return (
    <div className="sub game">
      {/* <div className="sub-top-area">
        <div className="info-area">
          <p>Mega Millions</p>
          <p className="prize">
            $ 1,5 <span>billion</span>
          </p>
          <p className="time">Close in : 1 days 11 hours</p>
        </div>
        <div className="img-area">
          <img src="" alt="" />
        </div>
      </div> */}
      <ul className="tab style01">
        <li className="current">Standard</li>
        <li>Lottery systems</li>
      </ul>
      <div className="standard-wrap">
        <div className="standard">
          <div className="step method">
            <div className="item">
              <h4 className="tit">1. 게임방식 선택</h4>
              <div className="btn-wrap">
                <div className="btn picked">Quick picks</div>
                <div className="btn">Choose numbers</div>
              </div>
            </div>
            <div className="item multiplier">
              <FormControlLabel
                control={<Switch />}
                label="Multiplier added"
                labelPlacement="start"
              />
              <p className="txt">$3.45 per standard game</p>
              <p className="exp">
                Select this option to multiply non-jackpot prizes by 2, 3, 4 or
                5 times!
              </p>
            </div>
          </div>
          <div className="step count">
            <h4 className="tit">2. 게임수량 선택</h4>
            <div className="btn-wrap">
              <div className="btn">1 Game</div>
              <div className="btn">2 Games</div>
              <div className="btn">3 Games</div>
              <div className="btn">5 Games</div>
              <div className="btn">10 Games</div>
              <div className="btn">20 Games</div>
            </div>
          </div>
          <div className="step ticket">
            <div className="tit-wrap">
              <div className="btn-wrap">
                <div className="btn fav">
                  My favorite
                  <img src={FavIco} alt="Favorite numbers" />
                </div>
                <div className="btn">
                  Clear all
                  <span className="material-symbols-rounded">delete</span>
                </div>
              </div>
            </div>
            <div className="ticket-wrap">
              <div className="item">
                <div className="ticket-tit-wrap">
                  <p className="">Game #1</p>
                  <div className="btn-wrap">
                    <span className="material-symbols-rounded">bolt</span>
                    <span className="btn material-symbols-rounded">
                      restart_alt
                    </span>
                    <img
                      className="btn"
                      src={fav ? FavIco : FavIcoChk}
                      onClick={() => {
                        setFav(!fav);
                      }}
                    />
                    <span className="btn material-symbols-rounded">delete</span>
                  </div>
                </div>
                <div className="picked-ball-wrap">
                  <div
                    className="ball-wrap"
                    onClick={() => {
                      setBallEditOpen(!ballEditOpen);
                    }}
                  >
                    <div className="ball">1</div>
                    <div className="ball">2</div>
                    <div className="ball">3</div>
                    <div className="ball">4</div>
                    <div className="ball">5</div>
                    <div className="ball mega">6</div>
                    <span
                      className={`material-symbols-rounded ${
                        ballEditOpen ? "open" : ""
                      }`}
                    >
                      keyboard_arrow_down
                    </span>
                  </div>
                  {ballEditOpen && (
                    <div className="edit-ball-wrap">
                      <div className="ball-count">
                        <div className="tit-count-wrap">
                          <p className="tit">Ball</p>
                          <p className="count">
                            <span>1</span>of 5
                          </p>
                        </div>
                      </div>

                      <div className="ball-wrap">
                        {[
                          1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16,
                          17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29,
                          30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42,
                          43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55,
                          56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68,
                          69, 70,
                        ].map((a, i) => {
                          return (
                            <div
                              className={`ball ${isPicked ? "picked" : ""}`}
                              key={i}
                              onClick={() => {
                                setIsPicked(!isPicked);
                              }}
                            >
                              {a}
                            </div>
                          );
                        })}
                      </div>

                      <div
                        className="tit-count-wrap"
                        style={{ marginTop: "12px" }}
                      >
                        <p className="mega">Mega ball</p>
                        <p className="count">
                          <span>1</span>of 1
                        </p>
                      </div>

                      <div className="ball-wrap mega">
                        {[
                          1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16,
                          17, 18, 19, 20, 21, 22, 23, 24, 25,
                        ].map((a, i) => {
                          return (
                            <div
                              className={`ball ${isPicked ? "picked" : ""}`}
                              key={i}
                              onClick={() => {
                                setIsPicked(!isPicked);
                              }}
                            >
                              {a}
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div className="item">
                <div className="ticket-tit-wrap">
                  <p className="">Game #2</p>
                  <div className="btn-wrap">
                    <span className="material-symbols-rounded">bolt</span>
                    <span className="btn material-symbols-rounded">
                      restart_alt
                    </span>
                    <img
                      className="btn"
                      src={fav ? FavIco : FavIcoChk}
                      onClick={() => {
                        setFav(!fav);
                      }}
                    />
                    <span className="btn material-symbols-rounded">delete</span>
                  </div>
                </div>
                <div className="picked-ball-wrap">
                  <div
                    className="ball-wrap"
                    onClick={() => {
                      setBallEditOpen(!ballEditOpen);
                    }}
                  >
                    <div className="ball">1</div>
                    <div className="ball">2</div>
                    <div className="ball">3</div>
                    <div className="ball">4</div>
                    <div className="ball">5</div>
                    <div className="ball mega">6</div>
                    <span
                      className={`material-symbols-rounded ${
                        ballEditOpen ? "open" : ""
                      }`}
                    >
                      keyboard_arrow_down
                    </span>
                  </div>
                  {ballEditOpen && (
                    <div className="edit-ball-wrap">
                      <div className="ball-count">
                        <div className="tit-count-wrap">
                          <p className="tit">Ball</p>
                          <p className="count">
                            <span>1</span>of 5
                          </p>
                        </div>
                      </div>

                      <div className="ball-wrap">
                        {[
                          1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16,
                          17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29,
                          30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42,
                          43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55,
                          56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68,
                          69, 70,
                        ].map((a, i) => {
                          return (
                            <div
                              className={`ball ${isPicked ? "picked" : ""}`}
                              key={i}
                              onClick={() => {
                                setIsPicked(!isPicked);
                              }}
                            >
                              {a}
                            </div>
                          );
                        })}
                      </div>

                      <div
                        className="tit-count-wrap"
                        style={{ marginTop: "12px" }}
                      >
                        <p className="mega">Mega ball</p>
                        <p className="count">
                          <span>1</span>of 1
                        </p>
                      </div>

                      <div className="ball-wrap mega">
                        {[
                          1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16,
                          17, 18, 19, 20, 21, 22, 23, 24, 25,
                        ].map((a, i) => {
                          return (
                            <div
                              className={`ball ${isPicked ? "picked" : ""}`}
                              key={i}
                              onClick={() => {
                                setIsPicked(!isPicked);
                              }}
                            >
                              {a}
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div className="item">
                <div className="ticket-tit-wrap">
                  <p className="">Game #3</p>
                  <div className="btn-wrap">
                    <span className="material-symbols-rounded">bolt</span>
                    <span className="btn material-symbols-rounded">
                      restart_alt
                    </span>
                    <img
                      className="btn"
                      src={fav ? FavIco : FavIcoChk}
                      onClick={() => {
                        setFav(!fav);
                      }}
                    />
                    <span className="btn material-symbols-rounded">delete</span>
                  </div>
                </div>
                <div className="picked-ball-wrap">
                  <div
                    className="ball-wrap"
                    onClick={() => {
                      setBallEditOpen(!ballEditOpen);
                    }}
                  >
                    <div className="ball">1</div>
                    <div className="ball">2</div>
                    <div className="ball">3</div>
                    <div className="ball">4</div>
                    <div className="ball">5</div>
                    <div className="ball mega">6</div>
                    <span
                      className={`material-symbols-rounded ${
                        ballEditOpen ? "open" : ""
                      }`}
                    >
                      keyboard_arrow_down
                    </span>
                  </div>
                  {ballEditOpen && (
                    <div className="edit-ball-wrap">
                      <div className="ball-count">
                        <div className="tit-count-wrap">
                          <p className="tit">Ball</p>
                          <p className="count">
                            <span>1</span>of 5
                          </p>
                        </div>
                      </div>

                      <div className="ball-wrap">
                        {[
                          1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16,
                          17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29,
                          30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42,
                          43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55,
                          56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68,
                          69, 70,
                        ].map((a, i) => {
                          return (
                            <div
                              className={`ball ${isPicked ? "picked" : ""}`}
                              key={i}
                              onClick={() => {
                                setIsPicked(!isPicked);
                              }}
                            >
                              {a}
                            </div>
                          );
                        })}
                      </div>

                      <div
                        className="tit-count-wrap"
                        style={{ marginTop: "12px" }}
                      >
                        <p className="mega">Mega ball</p>
                        <p className="count">
                          <span>1</span>of 1
                        </p>
                      </div>

                      <div className="ball-wrap mega">
                        {[
                          1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16,
                          17, 18, 19, 20, 21, 22, 23, 24, 25,
                        ].map((a, i) => {
                          return (
                            <div
                              className={`ball ${isPicked ? "picked" : ""}`}
                              key={i}
                              onClick={() => {
                                setIsPicked(!isPicked);
                              }}
                            >
                              {a}
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className={`step multiplier ${checked ? "on" : ""}`}>
            {checked && (
              <Lottie
                animationData={Lightning}
                className="lightning"
                loop={false}
              />
            )}
            <h4 className="tit">3. Megaplier</h4>
            <div className="layout">
              <FormControlLabel
                control={<Switch onChange={handleChange} />}
                label="Multiplier added"
              />

              <div className="txt-wrap">
                <p className="txt">$ 3.45 per combination</p>
                <p className="exp">
                  Boost the potential winnings up to 5 times on all non-jackpot
                  prizes!
                </p>
              </div>
            </div>
          </div>

          <div className="step subscribe">
            <div className="tit-wrap">
              <h4 className="tit">
                4. How many weeks?
                <Tooltip
                  title={
                    <>
                      <p>
                        How many draws would you like to use these numbers for?
                      </p>
                    </>
                  }
                >
                  <span className="material-symbols-rounded">info</span>
                </Tooltip>
              </h4>
              <p className="price">$ 7.00</p>
            </div>
            <div className="btn-wrap">
              <div className="btn">1</div>
              <div className="btn">2</div>
              <div className="btn">4</div>
              <div className="btn">8</div>
              <div className="btn">26</div>
              <div className="btn">52</div>
            </div>
          </div>
        </div>
        <div className="pick-info-wrap">
          <ul className="pick-info">
            <li>
              <span className="tit">Total game count</span>
              <span className="txt">6 games</span>
            </li>
            <li>
              <span className="tit">Game option</span>
              <span className="txt">Standard</span>
            </li>
            <li>
              <span className="tit">Mulitiplier</span>
              <span className="txt">X</span>
            </li>
            <li>
              <span className="tit">Weeks</span>
              <span className="txt">2 weeks</span>
            </li>
            <li className="total">
              <span className="tit">Total</span>
              <span className="txt">$ 72.00</span>
            </li>
            <div className="btn style01 md spc">
              <span>Add to cart</span>
              <span className="material-symbols-rounded">shopping_cart</span>
            </div>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default New;
