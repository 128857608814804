// tools
import { Dispatch, SetStateAction, useState } from "react";
import axios from "axios";
import Swal from "sweetalert2";
import User from "../../tools/userInfo";

// ui
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";

interface ModalContent {
  setOpen: Dispatch<SetStateAction<boolean>>;
}

const Notification = ({ setOpen }: ModalContent) => {
  const { api, info, token } = User();

  const [data, setData] = useState({
    sms: info.notify === "Y",
    app: info.marketing === "Y",
  });

  const notificationAxios = () => {
    axios
      .put(
        `${api}/auth/update`,
        {
          notify: data.sms ? "Y" : "N",
          marketing: data.app ? "Y" : "N",
        },
        {
          headers: {
            "ngrok-skip-browser-warning": "69420",
            Authorization: "bearer " + token,
          },
        }
      )
      .then(({ data }) => {
        if (data.message) {
          Swal.fire({
            title: "Update Completed",
            icon: "success",
            allowOutsideClick: false,
            didClose: () => location.reload(),
          });
          setOpen(false);
        }
      })
      .catch(({ response }) => {});
  };

  return (
    <div className="Notification">
      <div className="modal-tit-wrap">
        <h3 className="tit">Notification Settings</h3>
        <span
          className="btn material-symbols-outlined"
          onClick={() => setOpen(false)}
        >
          close
        </span>
      </div>
      <div className="modal-cont-wrap">
        <div className="notice-area">
          <p>
            Please note: You cannot unsubscribe from account related emails e.g.
            Order confirmation, Win notification, etc
          </p>
        </div>
        <form>
          <RadioGroup sx={{ width: "fit-content", margin: "0 auto" }}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={data.sms}
                  onChange={({ target }) => {
                    setData((prev) => ({
                      ...prev,
                      sms: target.checked,
                    }));
                  }}
                />
              }
              label="SMS"
            />
            <FormControlLabel
              control={
                <Checkbox
                  onChange={({ target }) => {
                    setData((prev) => ({
                      ...prev,
                      app: target.checked,
                    }));
                  }}
                  checked={data.app}
                />
              }
              label="App Push"
            />
          </RadioGroup>
        </form>
      </div>
      <div className="modal-bottom-wrap">
        <div className="btn-wrap">
          <div className="btn style01 sm" onClick={() => setOpen(false)}>
            Cancel
          </div>
          <div
            className="btn style01 sm white"
            style={{ padding: "12px 36px" }}
            onClick={() => notificationAxios()}
          >
            Set
          </div>
        </div>
      </div>
    </div>
  );
};

export default Notification;
