const NumberOnly = (value: string) => {
  let copy = value
  const regex = /^[0-9]*$/gi

  if (!regex.test(copy)) copy = copy.slice(0, -1)

  return copy
}

export default NumberOnly
