// img
import NoDataImg from "./../../img/sub/nodata.svg";

const NoData = () => {
  return (
    <div className="nodata">
      <img src={NoDataImg} alt="No Data Yet" />
    </div>
  );
};

export default NoData;
