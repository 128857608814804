// img
import EventImg01 from "../../img/main/event-img01.png";

const Event = () => {
  return (
    <section className="event-area">
      <div className="txt-wrap">
        <h3>COULD YOU BE THE</h3>
        <h2>NEXT WINNER?</h2>
      </div>
      <img src={EventImg01} alt="Could you be the next winner?" />
    </section>
  );
};

export default Event;
