// comp
import React from "react";
import Car from "./car";
import MagaHome from "./home";
import Watch from "./watch";
import Yacht from "./yacht";

interface TabMenu {
  menu: string;
}

const Category = ({ menu }: TabMenu) => {
  return (
    <div>
      {menu === "home" && <MagaHome />}
      {menu === "watch" && <Watch />}
      {menu === "car" && <Car />}
      {menu === "yacht" && <Yacht />}
    </div>
  );
};

export default Category;
