// components
import Wrapper from "../components/common/wrapper";
import Game from "../components/main/game";
import MagazineBar from "../components/main/magazine";
import News from "../components/main/news";
import Faq from "../components/main/faq";
import Event from "../components/main/event";

const Main = () => {
  return (
    <Wrapper>
      <div className="home">
        {/* Event area */}
        <Event />

        {/* Game area */}
        <Game />

        {/* Magazine area */}
        <MagazineBar />

        {/* Faq area */}
        <Faq />

        {/* News area */}
        <News />
      </div>
    </Wrapper>
  );
};

export default Main;
