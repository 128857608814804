// constants
import { MagazineTxt, MegazineCate } from "../../constants/magazineMenu";

// img
import RealEstate from "../../img/sub/magazine-estate.png";

const MagaHome = () => {
  return (
    <div className="maga-home">
      <div className="more-txt-area">
        {MagazineTxt.map((it) => (
          <div key={it.id} className="item">
            <h4>{it.text}</h4>
            <p>{it.content}</p>
          </div>
        ))}
      </div>

      <div className="cate-wrap">
        {MegazineCate.map((it) => (
          <div
            key={it.id}
            onClick={() => (location.href = `/magazine?tab=${it.href}`)}
            className="item style01"
          >
            <img src={it.img} alt="thumbnail" />
            <div className="txt-wrap">
              <h4>
                {it.text}
                <br />
                {it.text2}
              </h4>
              <div className="btn style01 sm outline">
                More<span className="material-symbols-rounded">add</span>
              </div>
            </div>
          </div>
        ))}
        <div
          className="item style02"
          onClick={() => (location.href = "/magazine?tab=estate")}
        >
          <div className="img-wrap">
            <img src={RealEstate} alt="thumbnail" />
          </div>
          <div className="txt-wrap">
            <h4>Real estate</h4>
            <p>
              Our dedicated service centre is professionally certified for real
              estate transactions. Every property is thoroughly inspected here
              for your peace of mind.
            </p>
            <div className="btn style01 sm outline">
              More<span className="material-symbols-rounded f16">add</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MagaHome;
