// tools
import { Dispatch, SetStateAction, useState } from "react";

// ui
import { TextField } from "@mui/material";

// img
import PayPal from "../../img/sub/paypal-ico.svg";
import PayPal02 from "../../img/sub/paypal-ico02.svg";

interface ModalContent {
  setOpen: Dispatch<SetStateAction<boolean>>;
}

const WithdrwalMethod = ({ setOpen }: ModalContent) => {
  const methods = ["bank", "addBank", "paypal"];
  const [expandedIndex, setExpandedIndex] = useState(0);

  const handleExpand = (index: number) => {
    setExpandedIndex((prevIndex) => (prevIndex === index ? prevIndex : index));
  };

  return (
    <div className="withdraw-method">
      <div className="modal-tit-wrap">
        <h3 className="tit">Manage Withdrawal Methods</h3>
        <span
          className="btn material-symbols-rounded"
          onClick={() => setOpen(false)}
        >
          close
        </span>
      </div>

      <div className="modal-cont-wrap">
        {methods.map((method, index) => (
          <div
            key={index}
            className={`item ${expandedIndex === index ? "open" : ""}`}
            onClick={() => handleExpand(index)}
          >
            {method === "bank" && (
              <div className="bank">
                <div className="summary">
                  <span className="material-symbols-rounded">
                    account_balance
                  </span>
                  <p className="bank-name">KAKAO bank</p>
                </div>
                {expandedIndex === index && (
                  <div className="expand">
                    <p className="num">1234-0000-0000</p>
                  </div>
                )}
              </div>
            )}

            {method === "addBank" && (
              <div className="add-bank">
                <div className="summary">
                  <span className="material-symbols-rounded">
                    account_balance
                  </span>
                  <p className="tit">Add Bank Account</p>
                </div>
                {expandedIndex === index && (
                  <div className="expand">
                    <form>
                      <div className="input-wrap">
                        <TextField label="BSA" fullWidth />
                      </div>
                      <div className="input-wrap">
                        <TextField label="Account Number" fullWidth />
                      </div>
                    </form>
                    <div className="btn-wrap">
                      <div className="btn style01 sm">SAVE</div>
                    </div>
                  </div>
                )}
              </div>
            )}

            {method === "addCard" && (
              <div className="add-card">
                <div className="summary">
                  <span className="material-symbols-rounded">add_card</span>
                  <p className="tit">Add New Card</p>
                </div>
                {expandedIndex === index && (
                  <div className="expand">
                    <div className="btn-wrap">
                      <div className="btn style01">Add</div>
                    </div>
                  </div>
                )}
              </div>
            )}

            {method === "paypal" && (
              <div className="add-paypal">
                <div className="summary">
                  <img src={PayPal} alt="PayPal" />
                  <p className="tit">Add PayPal</p>
                </div>

                {expandedIndex === index && (
                  <div className="expand">
                    <div className="btn paypal style02">
                      <img
                        src={PayPal02}
                        alt="PayPal"
                        style={{ width: "100px" }}
                      />
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>
        ))}
      </div>
      <div className="modal-bottom-wrap">
        <div className="btn-wrap">
          <div className="btn style01 sm" onClick={() => setOpen(false)}>
            Cancel
          </div>
          <div
            className="btn style01 sm white"
            style={{ padding: "12px 36px" }}
          >
            Set
          </div>
        </div>
      </div>
    </div>
  );
};

export default WithdrwalMethod;
