// tools
import { useState, useEffect } from "react";
import axios from "axios";
import User from "../../tools/userInfo";

interface BarChart {
  type: string;
  menu: string;
}

interface Ball {
  number: number;
  count: number;
}

interface Data {
  total: number;
  white: Ball[];
  special: Ball[];
}

const Bar = ({ type, menu }: BarChart) => {
  const { api } = User();
  const BallType = type === "power" ? "Power Ball" : "Mega Ball";
  const [list, setList] = useState<Data>({
    total: 0,
    white: [],
    special: [],
  });

  const dataAxios = () => {
    axios
      .get(`${api}/number/${menu}?type=${type === "power" ? "pb" : "mm"}`, {
        headers: {
          "ngrok-skip-browser-warning": "69420",
        },
      })
      .then(({ data }) => {
        const Data = data.data;
        if (menu === "frequency") {
          setList({
            total: Data.total_drawnum,
            white: Data.white,
            special: Data.power,
          });
        } else {
          const white = [];
          const special = [];

          for (let i = 0; i < Data.white.length; i++) {
            white.push({
              number: Data.white[i].number,
              count: Data.white[i].timesince,
            });
          }

          for (let i = 0; i < Data.power.length; i++) {
            special.push({
              number: Data.power[i].number,
              count: Data.power[i].timesince,
            });
          }

          setList({
            total: Data.total_drawnum,
            white,
            special,
          });
        }
      });
  };
  useEffect(() => {
    dataAxios();
  }, []);
  return (
    <div className="bars">
      <div className="item">
        <h4 className="tit">White Ball</h4>
        <table>
          <tr>
            <th>Number</th>
            <th>
              {menu === "frequency" ? "Frequency Graph" : "Appearence Graph"}
            </th>
            <th>{menu === "frequency" ? "Frequency" : "Last Appearence"}</th>
            {menu === "frequency" ? <th>Ratio</th> : <></>}
          </tr>

          {list.white.map((item) => {
            const max = list.white.reduce((prev, value) => {
              return prev.count >= value.count ? prev : value;
            });

            return (
              <tr key={item.number}>
                <td>{item.number}</td>
                <td>
                  <div
                    className="bar"
                    style={{
                      width: `${(item.count / max.count) * 100}%`,
                    }}
                  />
                </td>
                <td className="border">
                  {menu === "frequency"
                    ? `${item.count} times`
                    : `${
                        item.count ? `${item.count + 1} draws ago` : "last draw"
                      }`}
                </td>
                {menu === "frequency" ? (
                  <td>{((item.count / list.total) * 100).toFixed(1)}%</td>
                ) : (
                  <></>
                )}
              </tr>
            );
          })}
        </table>
      </div>

      <div className="item">
        <h4 className={type === "power" ? "power" : "mega"}>{BallType}</h4>
        <table>
          <tr>
            <th>Number</th>
            <th>
              {menu === "frequency" ? "Frequency Graph" : "Appearence Graph"}
            </th>
            <th>{menu === "frequency" ? "Frequency" : "Last Appearence"}</th>
            {menu === "frequency" ? <th>Ratio</th> : <></>}
          </tr>

          {list.special.map((item) => {
            const max = list.special.reduce((prev, value) => {
              return prev.count >= value.count ? prev : value;
            });
            return (
              <tr key={item.number}>
                <td>
                  <div>
                    <div className={`ball ${type}`}>{item.number}</div>
                  </div>
                </td>
                <td>
                  <div
                    className={type === "power" ? "power bar" : "mega bar"}
                    style={{
                      width: `${(item.count / max.count) * 100}%`,
                    }}
                  ></div>
                </td>
                <td className="border">
                  {menu === "frequency"
                    ? `${item.count} times`
                    : `${
                        item.count ? `${item.count + 1} draws ago` : "last draw"
                      }`}
                </td>
                {menu === "frequency" ? (
                  <td>{((item.count / list.total) * 100).toFixed(1)}%</td>
                ) : (
                  <></>
                )}
              </tr>
            );
          })}
        </table>
      </div>
    </div>
  );
};

export default Bar;
