// tools
import { Pagination } from "@mui/material";
import { Link } from "react-router-dom";

// img
import NewsImg from "../img/sub/newsimg.png";

const News = () => {
  return (
    <div className="sub news">
      <div className="sub-top-area">
        <h3 className="tit">News</h3>
      </div>

      <div className="news-wrap">
        <div className="news-item-wrap">
          <Link to="" className="item">
            <div className="img-wrap">
              <img src={NewsImg} alt="" />
            </div>
            <div className="txt-wrap">
              <p className="tit">
                Powerball player wins $2 billion after excruciating delay
              </p>

              <p className="site">Publisher: news.com.au</p>
              <p className="date">Published date: 8 April, 2024</p>
            </div>
          </Link>
          <Link to="" className="item">
            <div className="img-wrap">
              <img src={NewsImg} alt="" />
            </div>
            <div className="txt-wrap">
              <p className="tit">
                Powerball player wins $2 billion after excruciating delay
              </p>

              <p className="site">Publisher: news.com.au</p>
              <p className="date">Published date: 8 April, 2024</p>
            </div>
          </Link>
          <Link to="" className="item">
            <div className="img-wrap">
              <img src={NewsImg} alt="" />
            </div>
            <div className="txt-wrap">
              <p className="tit">
                Powerball player wins $2 billion after excruciating delay
              </p>

              <p className="site">Publisher: news.com.au</p>
              <p className="date">Published date: 8 April, 2024</p>
            </div>
          </Link>
          <Link to="" className="item">
            <div className="img-wrap">
              <img src={NewsImg} alt="" />
            </div>
            <div className="txt-wrap">
              <p className="tit">
                Powerball player wins $2 billion after excruciating delay
              </p>

              <p className="site">Publisher: news.com.au</p>
              <p className="date">Published date: 8 April, 2024</p>
            </div>
          </Link>
          <Link to="" className="item">
            <div className="img-wrap">
              <img src={NewsImg} alt="" />
            </div>
            <div className="txt-wrap">
              <p className="tit">
                Powerball player wins $2 billion after excruciating delay
              </p>

              <p className="site">Publisher: news.com.au</p>
              <p className="date">Published date: 8 April, 2024</p>
            </div>
          </Link>
        </div>
        <Pagination className="pagination" />
      </div>
    </div>
  );
};

export default News;
