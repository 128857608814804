// tools
import { Dispatch, SetStateAction, useState } from "react";

// ui
import { FormControlLabel, Radio, RadioGroup, TextField } from "@mui/material";

// img
import Mastercard from "../../img/sub/mastercard-ico.svg";
import Visa from "../../img/sub/visa-ico.svg";
import Discover from "../../img/sub/discover-ico.svg";
import PayPal from "../../img/sub/paypal-ico.svg";
import PayPal02 from "../../img/sub/paypal-ico02.svg";

interface ModalContent {
  setOpen: Dispatch<SetStateAction<boolean>>;
}

const PayMethod = ({ setOpen }: ModalContent) => {
  const methods = ["card", "addCard", "paypal"];
  const [expandedIndex, setExpandedIndex] = useState(0);

  const handleExpand = (index: number) => {
    setExpandedIndex((prevIndex) => (prevIndex === index ? prevIndex : index));
  };

  return (
    <div className="pay-method">
      <div className="modal-tit-wrap">
        <h3 className="tit">Manage Payment Methods</h3>
        <span
          className="btn material-symbols-rounded"
          onClick={() => setOpen(false)}
        >
          close
        </span>
      </div>
      <div className="modal-cont-wrap">
        {methods.map((method, index) => (
          <div
            key={index}
            className={`item ${expandedIndex === index ? "open" : ""}`}
            onClick={() => handleExpand(index)}
          >
            {method === "card" && (
              <div className="card">
                <div className="summary">
                  <img src={Mastercard} alt="Master Card" />
                  <p className="f18">5217 29xx xxxx x084</p>
                </div>
                <p className="expired">EXPIRED</p>
                {expandedIndex === index && (
                  <div className="expand">
                    <div className="cardholder">
                      <p className="tit">CARD HOLDER</p>
                      <p className="txt">KIM GU</p>
                    </div>
                    <div className="expries">
                      <p className="tit">Expries</p>
                      <p className="txt">11/24</p>
                    </div>
                  </div>
                )}
              </div>
            )}

            {method === "addCard" && (
              <div className="add-card">
                <div className="summary">
                  <span className="material-symbols-rounded">add_card</span>
                  <p className="tit">Add New Card</p>
                </div>
                {expandedIndex === index && (
                  <div className="expand">
                    <form>
                      <div className="input-wrap">
                        <RadioGroup row name="">
                          <FormControlLabel
                            value="mastercard"
                            control={<Radio />}
                            label={<img src={Mastercard} alt="Mastercard" />}
                          />
                          <FormControlLabel
                            value="visa"
                            control={<Radio />}
                            label={<img src={Visa} alt="Visa" />}
                          />
                          <FormControlLabel
                            value="discover"
                            control={<Radio />}
                            label={
                              <img
                                src={Discover}
                                alt="Discover"
                                style={{ width: "80px" }}
                              />
                            }
                          />
                        </RadioGroup>
                      </div>
                      <div className="input-wrap">
                        <TextField label="Card Number" fullWidth />
                      </div>

                      <div className="input-wrap">
                        <TextField
                          label="Card Name"
                          placeholder="Name as it appears on your card"
                          fullWidth
                        />
                      </div>

                      <div className="input-wrap">
                        <TextField
                          label="Expiration Date"
                          placeholder="MM/YYYY"
                          fullWidth
                        />

                        <TextField label="CVV" placeholder="123" fullWidth />
                      </div>
                    </form>
                    <div className="btn-wrap">
                      <div className="btn style01 sm">Cancel</div>
                      <div
                        className="btn style01 sm white"
                        style={{ padding: "12px 24px" }}
                      >
                        Add
                      </div>
                    </div>
                  </div>
                )}
              </div>
            )}

            {method === "paypal" && (
              <div className="add-paypal">
                <div className="summary">
                  <img src={PayPal} alt="PayPal" />
                  <p className="tit">Add PayPal</p>
                </div>

                {expandedIndex === index && (
                  <div className="expand">
                    <div className="btn style02">
                      <img
                        src={PayPal02}
                        alt="PayPal"
                        style={{ width: "100px" }}
                      />
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>
        ))}
      </div>
      <div className="modal-bottom-wrap">
        <div className="btn-wrap">
          <div className="btn style01 sm" onClick={() => setOpen(false)}>
            Cancel
          </div>
          <div
            className="btn style01 sm white"
            style={{ padding: "12px 36px" }}
          >
            Set
          </div>
        </div>
      </div>
    </div>
  );
};

export default PayMethod;
