// tools
import {
  createContext,
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from "react";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { Outlet } from "react-router-dom";
import axios from "axios";
import User from "./tools/userInfo";

// img
import DefaultBg01 from "./img/common/default-bg01.png";
import DefaultBg02 from "./img/common/default-bg02.svg";
import DefaultBgSignup from "./img/sub/signup-bg.png";

// layout base
import Header from "./components/common/header";
import SideBar from "./components/common/sideBar";
import Footer from "./components/common/footer";

// mode set
const darkTheme = createTheme({
  palette: {
    mode: "dark",
  },
  components: {
    MuiInputLabel: {
      styleOverrides: {
        root: {
          "&.Mui-focused": {
            color: "#fff",
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: "#fff",
          },
          "&.mui-cofused": {
            color: "#fff",
          },
        },
      },
    },
  },
});

// type
export interface LottoProps {
  auDrawTime: string;
  auOrderTime: string;
  currency: string;
  dayWeek: number;
  drawnum: number;
  exchange: string;
  initial: number;
  isDrawdate: number;
  jackpot: number;
  playdate: string;
  type: string;
  usDrawTime: string;
}

// Context 생성
const LottoContext = createContext<
  | {
      lottery: LottoProps[];
      loading: boolean;
      open: boolean;
      setOpen: Dispatch<SetStateAction<boolean>>;
    }
  | undefined
>(undefined);

export const useLotto = () => {
  const context = useContext(LottoContext);
  if (!context) {
    throw new Error("useLotto must be used within a LottoProvider");
  }
  return context;
};

const LottoProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const { api } = User();
  const [lottery, setLottery] = useState<LottoProps[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [open, setOpen] = useState<boolean>(false);

  // 당첨 및 추첨 정보
  useEffect(() => {
    axios
      .get(`${api}/purchase`, {
        headers: {
          "ngrok-skip-browser-warning": "69420",
        },
      })
      .then(({ data }) => {
        if (data.message === "success") {
          setLottery(data.data);
          setTimeout(() => setLoading(false), 1000);
        }
      })
      .catch((response) => {});
  }, [api]);

  return (
    <LottoContext.Provider value={{ lottery, loading, open, setOpen }}>
      {children}
    </LottoContext.Provider>
  );
};

function App() {
  // mobile check
  const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
  const [isScrolled, setIsScrolled] = useState(false);
  const [showSideBar, setShowSideBar] = useState(
    location.pathname !== "/signup"
  );

  useEffect(() => {
    if (isMobile && location.href.includes("www")) {
      const m = location.href.replace("www", "m");
      location.replace(m);
    }
  }, [location]);

  // Header bg effect
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 50) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    setShowSideBar(
      !location.pathname.startsWith("/welcome") &&
        !location.pathname.startsWith("/signup")
    );
  }, [location.pathname]);

  return (
    <GoogleOAuthProvider clientId="1710970034-r4l1dgphpsorfqh01upn8ogjmglf143f.apps.googleusercontent.com">
      <ThemeProvider theme={darkTheme}>
        <LottoProvider>
          {/* Default bg */}
          <img src={DefaultBg01} alt="bg-1" className="default-bg01" />
          <div className="star-wrap">
            <div className="star"></div>
            <div className="star2"></div>
            <div className="star3"></div>
          </div>
          {!location.pathname.startsWith("/signup") && (
            <img src={DefaultBg02} alt="bg-2" className="default-bg02" />
          )}

          {location.pathname.startsWith("/signup") && (
            <img
              src={DefaultBgSignup}
              alt="Signup"
              className="default-bg-signup"
            />
          )}

          <Header isScrolled={isScrolled} />

          <div className="cont-wrapper inner">
            {!location.pathname.startsWith("/signup") &&
              !location.pathname.startsWith("/welcome") && <SideBar />}
            <div className={showSideBar ? "cont" : "cont signup"}>
              <Outlet />
              <Footer />
            </div>
          </div>
        </LottoProvider>
      </ThemeProvider>
    </GoogleOAuthProvider>
  );
}

export default App;
