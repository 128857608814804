import React from "react";

// comp
import Article from "./article";
import Wrapper from "../common/wrapper";

// style
import "../../styles/magazine.scss";

// assets
const images = [
  "https://cdn.pixabay.com/photo/2018/05/08/16/23/sports-car-3383404_1280.jpg",
  "https://cdn.pixabay.com/photo/2023/01/29/08/06/steampunk-7752630_1280.jpg",
  "https://cdn.pixabay.com/photo/2024/06/28/06/21/dare-8858672_1280.jpg",
  "https://cdn.pixabay.com/photo/2018/01/11/17/32/porsche-911-gt2rs-3076518_1280.jpg",
];

const Car = () => {
  return (
    <Wrapper>
      <div className="maga-yacht">
        <section className="maga-section">
          <h3 className="tit">Latest Articles</h3>
          <div className="article">
            {images.map((image, index) => (
              <Article image={image} index={index} key={index} />
            ))}
          </div>
        </section>
      </div>
    </Wrapper>
  );
};

export default Car;
