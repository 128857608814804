// tools
import React, { useState, useRef, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { error } from "console";
import { parsePrice } from "../../tools/parsePrice";
import Swal from "sweetalert2";

// img
import Thumb01 from "../../img/sub/article-thumb01.png";
import Thumb02 from "../../img/sub/article-thumb02.png";
import Thumb03 from "../../img/sub/article-thumb03.png";
import Thumb04 from "../../img/sub/article-thumb04.png";

// ui
import Slider from "react-slick";

// comp
import Wrapper from "../common/wrapper";

const thumbs = [Thumb01, Thumb02, Thumb03, Thumb04];

const MagaDetail = () => {
  const [data, setData] = useState<any>(dummy);
  const [showAll, setShowAll] = useState<boolean>(false);

  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const cate = query.get("cate");
  const id = query.get("id");

  const prices = data.store.map((it: any) => parsePrice(it.price));
  const lowestPrice = Math.min(...prices);

  const sortedItems = data.store.sort(
    (a: any, b: any) => parsePrice(a.price) - parsePrice(b.price)
  );

  const copyToClip = () => {
    const url = window.location.href;

    try {
      navigator.clipboard.writeText(url).then(() => {
        Swal.fire({
          icon: "success",
          text: "The product address has been copied.",
        });
      });
    } catch (error) {
      console.log(error);
    }
  };
  const NextArrow = (props: any) => {
    const { onClick } = props;
    return (
      <div className="btn arr next" onClick={onClick}>
        <span className="material-symbols-outlined">arrow_forward_ios</span>
      </div>
    );
  };

  const ref = useRef<Slider | null>(null);

  const PrevArrow = (props: any) => {
    const { onClick } = props;
    return (
      <div className="btn arr prev" onClick={onClick}>
        <span
          className="material-symbols-outlined"
          style={{ marginRight: "-12px" }}
        >
          arrow_back_ios
        </span>
      </div>
    );
  };
  const settings = {
    customPaging: function (i: number) {
      return (
        <div className="thumbs">
          <img src={thumbs[i]} alt="" />
        </div>
      );
    },
    dots: true,
    dotsClass: "slick-dots slick-thumb",
    infinite: true,
    arrows: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
  };

  useEffect(() => {
    setData(dummy);
  }, []);

  return (
    <Wrapper>
      <div className="maga-detail">
        <div className="inner">
          <section className="maga-section detail-area">
            <div className="detail-slider">
              <Slider {...settings} ref={ref}>
                {thumbs.map((thumb, i) => {
                  return (
                    <div className="slider" key={i}>
                      <img src={thumbs[i]} alt="" />
                    </div>
                  );
                })}
              </Slider>
            </div>
            <div className="detail-info">
              <div className="product-info">
                <button
                  className="btn share style03"
                  onClick={() => copyToClip()}
                >
                  <span className="material-symbols-outlined">share</span>
                </button>

                <a href="/magazine?tab=watch" className="cate btn">
                  {cate}
                  <span className="material-symbols-outlined f16">
                    chevron_right
                  </span>
                </a>
                <p className="f24 brand">{data.brand}</p>
                <p className="f40 name">{data.name}</p>
                <p className="f16 num">{data.serial}</p>
              </div>
              <ul className="compare-prices">
                {sortedItems
                  .slice(0, showAll ? data.store.length : 5)
                  .map((it: any, idx: any) => {
                    const parsedPrice = parsePrice(it.price);
                    return (
                      <li key={idx}>
                        <p className="f18 site">{it.storeName}</p>
                        <p
                          className={`price ${
                            parsedPrice === lowestPrice ? "lowest" : ""
                          }`}
                        >
                          $ {it.price}
                        </p>
                        <div className="btn maga-style02">Shop Now</div>
                      </li>
                    );
                  })}
                {!showAll && data.store.length > 5 && (
                  <div
                    className="btn style02"
                    style={{ marginTop: ".5rem", width: "100%" }}
                    onClick={() => setShowAll((prev) => !prev)}
                  >
                    View more +
                  </div>
                )}
              </ul>
            </div>
          </section>

          <section className="maga-section spec-area">
            <h3 className="tit f24">Specification</h3>
            <div className="spec-wrap">
              <table className="spec">
                <tbody>
                  <tr>
                    <th>Box</th>
                    <td>{data.info.box}</td>
                  </tr>
                  <tr>
                    <th>Papers</th>
                    <td>{data.info.paper}</td>
                  </tr>
                  <tr>
                    <th>Year</th>
                    <td>{data.info.year}</td>
                  </tr>
                  <tr>
                    <th>Product Code</th>
                    <td>{data.info.code}</td>
                  </tr>
                  <tr>
                    <th>Manufacturer Warranty</th>
                    <td>{data.info.warrantyM}</td>
                  </tr>
                </tbody>
              </table>
              <table className="spec">
                <tbody>
                  <tr>
                    <th>Lottery Cluster Warranty</th>
                    <td>{data.info.warrantyL}</td>
                  </tr>
                  <tr>
                    <th>Case Size</th>
                    <td>{data.info.size}</td>
                  </tr>
                  <tr>
                    <th>Case Material</th>
                    <td>{data.info.material}</td>
                  </tr>
                  <tr>
                    <th>Movement</th>
                    <td>{data.info.move}</td>
                  </tr>
                  <tr>
                    <th>Bracelet</th>
                    <td>{data.info.bracelet}</td>
                  </tr>
                </tbody>
              </table>
              <table className="spec">
                <tbody>
                  <tr>
                    <th>Dial Type</th>
                    <td>{data.info.type}</td>
                  </tr>
                  <tr>
                    <th>Water Resistance</th>
                    <td>{data.info.water}s</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </section>
        </div>
      </div>
    </Wrapper>
  );
};

export default MagaDetail;

const dummy = {
  brand: "Tag Heuer",
  name: "Monaco",
  serial: "CAW2119.FC6289",
  store: [
    {
      storeName: "COUPANG",
      price: "25,420",
    },
    {
      storeName: "로랜텍",
      price: "22,420",
    },
    {
      storeName: "애플PIG",
      price: "15,530",
    },
    {
      storeName: "COUPANG",
      price: "55,420",
    },
    {
      storeName: "로랜텍",
      price: "23,420",
    },
    {
      storeName: "애플PIG",
      price: "16,530",
    },
    {
      storeName: "COUPANG",
      price: "35,420",
    },
    {
      storeName: "로랜텍",
      price: "22,420",
    },
    {
      storeName: "애플PIG",
      price: "19,530",
    },
  ],
  info: {
    box: "yes",
    paper: "yes",
    year: "2013",
    code: "248065",
    warrantyM: "1 Year 9 Months remaining",
    warrantyL: "24 months",
    size: "38 MM",
    material: "Steel",
    move: "Automatic",
    bracelet: "Leater Alligator Grain - Black (Adjustable)",
    type: "Black Baton",
    water: "100 meters",
  },
};
