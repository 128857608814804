// constants.js

import ResultIcon from "../img/icon/sack-dollar-solid.svg";
import MegaIcon from "../img/icon/mega-icon.svg";
import PowerIcon from "../img/icon/power-icon.svg";
import StatIcon from "../img/icon/chart-pie-solid.svg";
import MagaIcon from "../img/icon/newspaper-solid.svg";
import NewsIcon from "../img/icon/bullhorn-solid.svg";
import ContactIcon from "../img/icon/headset-solid.svg";
import FaqIcon from "../img/icon/circle-question-solid.svg";
import MypageIcon from "../img/icon/circle-user-solid.svg";

export const SideMenu = [
  {
    tab: "item mega",
    href: "/mega",
    img: MegaIcon,
    title: "Mega Millions",
    group: "mega millions",
  },
  {
    tab: "item power",
    href: "/power",
    img: PowerIcon,
    title: "Powerball",
    group: "powerball",
  },
  {
    tab: "item result",
    href: "/result?tab=mega",
    img: ResultIcon,
    title: "Results",
    subItems: [
      {
        tab: "sub-item",
        href: "/result?tab=mega",
        title: "Mega Millions",
        group: "result",
      },
      {
        tab: "sub-item",
        href: "/result?tab=power",
        title: "Powerball",
        group: "result",
      },
    ],
  },
  {
    tab: "item stat",
    href: "/stat?type=power&tab=frequency",
    img: StatIcon,
    title: "Statistics",
    group: "stat",
    subItems: [
      {
        tab: "sub-item",
        href: "/stat?type=power&tab=frequency",
        title: "Frequency numbers",
        group: "stat",
      },
      {
        tab: "sub-item",
        href: "/stat?type=power&tab=lastdrawn",
        title: "Last appearances",
        group: "stat",
      },
      {
        tab: "sub-item",
        href: "/stat?type=power&tab=oddeven",
        title: "Odd and even numbers",
        group: "stat",
      },
      {
        tab: "sub-item",
        href: "/stat?type=power&tab=consecutive",
        title: "Consecutive numbers",
        group: "stat",
      },
      {
        tab: "sub-item",
        href: "/stat?type=power&tab=highlow",
        title: "High and low numbers",
        group: "stat",
      },
      {
        tab: "sub-item",
        href: "/stat?type=power&tab=pattern",
        title: "Lucky number patterns",
        group: "stat",
      },
    ],
  },
  {
    tab: "item maga",
    href: "/magazine?tab=estate",
    img: MagaIcon,
    title: "Magazine",
    group: "magazine",
    subItems: [
      {
        tab: "sub-item",
        href: "/magazine?tab=estate",
        title: "Real estate",
        group: "magazine",
      },
      {
        tab: "sub-item",
        href: "/magazine?tab=watch",
        title: "Watch",
        group: "magazine",
      },
      {
        tab: "sub-item",
        href: "/magazine?tab=yacht",
        title: "Yacht",
        group: "magazine",
      },
      {
        tab: "sub-item",
        href: "/magazine?tab=car",
        title: "Car",
        group: "magazine",
      },
      // {
      //   tab: "sub-item",
      //   href: "/magazine?tab=life",
      //   title: "Life",
      //   group: "magazine",
      // },
      // {
      //   tab: "sub-item",
      //   href: "/magazine?tab=tech",
      //   title: "Tech",
      //   group: "magazine",
      // },
    ],
  },
  {
    tab: "item news",
    href: "/news",
    img: NewsIcon,
    title: "News",
    group: "news",
  },
  {
    tab: "item contact",
    href: "/email",
    img: ContactIcon,
    title: "Contact us",
    group: "contact",
  },
  {
    tab: "item faq",
    href: "/faq",
    img: FaqIcon,
    title: "FAQ",
    group: "faq",
  },
  {
    tab: "item mypage",
    href: "/mypage",
    img: MypageIcon,
    title: "Mypage",
    group: "mypage",
  },
];
