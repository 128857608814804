// tools
import { Link } from "react-router-dom";

// img
import ErrorImg from "../img/common/error-img.png";

const ErrorPage = () => {
  return (
    <div className="error">
      <div className="star-wrap">
        <div className="star"></div>
        <div className="star2"></div>
        <div className="star3"></div>
      </div>

      <div className="error-wrap">
        <img src={ErrorImg} alt="" className="img" />
        <div className="txt-wrap">
          <p className="f404">404</p>
          <p className="txt1">Oops! Something went wrong.</p>
          <p className="txt2">
            The page you're searching for cannot be found. But here are some
            <br />
            useful links instead:
          </p>

          <Link to="/" className="btn style01 md">
            Home
          </Link>
        </div>
      </div>
    </div>
  );
};

export default ErrorPage;
