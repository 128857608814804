import { create } from "zustand";
import { persist } from "zustand/middleware";

// type
export interface Info {
  [key: string]: string | number | null | Cart;
  address: string;
  birth: string;
  cash: number;
  city: string;
  country: string;
  createdAt: string;
  email: string;
  firstName: string;
  gender: string;
  lastName: string;
  level: number;
  marketing: string;
  notify: string;
  number: null | string | number;
  phone: string;
  unit: null | string;
  provider: string;
  prize: number;
  totalPrize: number;
  weeklySpendLimit: number;
  remaining: number;
  cart: null | Cart;
}

export interface Cart {
  game: {
    id: string;
    type: string;
    option: string;
    ball: number[][];
    cartAt: string;
    total: number;
    price: number;
  }[];
  totalCart: number;
  totalPrice: number;
}

interface UserInfo {
  // has data
  info: Info;
  token: string;
  api: string;

  // has func
  setToken: (input: string) => void;
  setInfo: (key: string, value: string | number) => void;
  setTotalInfo: (info: Info) => void;
}

const User = create(
  persist<UserInfo>(
    (set, get) => ({
      // set init
      info: {
        address: "",
        birth: "",
        cash: 0,
        city: "",
        country: "",
        createdAt: "",
        email: "",
        firstName: "",
        gender: "",
        lastName: "",
        level: 0,
        marketing: "",
        notify: "",
        number: "",
        phone: "",
        unit: "",
        provider: "",
        prize: 0,
        totalPrize: 0,
        weeklySpendLimit: 0,
        remaining: 0,
        cart: null,
      },

      token: "",
      api: "/api",

      // set func
      setToken: (input: string) => set((state) => ({ ...state, token: input })),

      setInfo: (key, value) => {
        const info = get().info;
        info[key] = value;
        set((state) => ({ ...state, info: info }));
      },

      setTotalInfo: (info) => {
        set((state) => ({ ...state, info: info }));
      },
    }),
    {
      name: "userinfo",
    }
  )
);

export default User;
