// props
import Countdown, { CountdownRenderProps } from "react-countdown";

interface ClockProps {
  date: string;
  pending: number;
}

const CountDown = ({ date, pending }: ClockProps) => {
  return (
    <Countdown
      date={date}
      renderer={(props) => <CountDownView {...props} pending={pending} />}
      intervalDelay={60000}
    />
  );
};

export default CountDown;

const CountDownView = ({
  days,
  hours,
  minutes,
  pending,
}: CountdownRenderProps & { pending: number }) => {
  const renderTime = () => {
    if (pending) {
      // pending = truthy
      if (days > 0) {
        return `Next draw : ${days} days, ${hours} hrs`;
      } else if (hours > 0 || minutes > 0) {
        return `Next draw : ${hours} hrs, ${minutes} mins`;
      } else {
        return `Next soon`;
      }
    } else {
      // pending = falsy
      if (days > 0) {
        return `Close in : ${days} days, ${hours} hrs`;
      } else if (hours > 0 || minutes > 0) {
        return `Close in : ${hours} hrs, ${minutes} mins`;
      } else {
        return `Close soon`;
      }
    }
  };

  return <p className="countdown">{renderTime()}</p>;
};
