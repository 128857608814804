// tools
import React, { useEffect, useRef, useState } from "react";
import { url } from "inspector";

// ui
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

// comp
import VideoModal from "./videoModal";

const MagazineSlider = () => {
  const [tab, setTab] = useState<string>("");
  const [isModal, setIsModal] = useState<boolean>(false);
  const [target, setTarget] = useState<string>("");

  const playVideo = (key: string) => {
    setTarget(key);
    setIsModal((prev) => !prev);
  };

  useEffect(() => {
    const search = location.search.slice(1, location.search.length);

    if (search) {
      const split = search.split("&")[0];
      const menu = split.split("=")[1];

      setTab(menu);
    } else {
      setTab("home");
    }
  }, []);

  const NextArrow = (props: any) => {
    const { onClick } = props;
    return (
      <div className="btn arr next" onClick={onClick}>
        <span className="material-symbols-rounded">arrow_forward_ios</span>
      </div>
    );
  };

  const PrevArrow = (props: any) => {
    const { onClick } = props;
    return (
      <div className="btn arr prev" onClick={onClick}>
        <span
          className="material-symbols-rounded"
          style={{ marginRight: "-6px" }}
        >
          arrow_back_ios
        </span>
      </div>
    );
  };

  const ref = useRef<Slider | null>(null);

  const changeCateCarousel = (select: string) => {
    switch (select) {
      case "estate":
        ref.current?.slickGoTo(0, true);
        break;
      case "car":
        ref.current?.slickGoTo(1, true);
        break;
      case "watch":
        ref.current?.slickGoTo(2, true);
        break;
      case "yacht":
        ref.current?.slickGoTo(3, true);
        break;
      case "life":
        ref.current?.slickGoTo(4, true);
        break;
      case "tech":
        ref.current?.slickGoTo(5, true);
        break;
      default:
        return;
    }
  };

  useEffect(() => {
    changeCateCarousel(tab);
  }, [tab]);

  const SliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    autoplaySpeed: 5000,
    autoplay: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    draggable: true,
    pauseOnHover: true,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
  };

  return (
    <>
      {isModal && <VideoModal playVideo={playVideo} src={target} />}
      <div className="slider-wrap">
        <Slider {...SliderSettings} ref={ref}>
          <div
            className="slider item01"
            onClick={() => playVideo("VjNsgIaTPgk")}
          />
          <div
            className="slider item02"
            onClick={() => playVideo("IEQkph1dmmo")}
          />
          <div
            className="slider item03"
            onClick={() => playVideo("fEbqW7KW-bQ")}
          />
          <div
            className="slider item04"
            onClick={() => playVideo("FpYzkzYtliU")}
          />
          <div
            className="slider item05"
            onClick={() => playVideo("d0ykSLUI-LQ")}
          >
            <img
              src="https://cdn.pixabay.com/photo/2020/11/19/09/28/perfume-5758011_1280.jpg"
              style={{ objectFit: "cover", width: "100%", height: "100%" }}
            />
          </div>
          <div
            className="slider item06"
            onClick={() => playVideo("XHTrLYShBRQ")}
          >
            <img
              src="https://cdn.pixabay.com/photo/2014/12/10/12/28/iphone-563071_1280.jpg"
              style={{ objectFit: "cover", width: "100%", height: "100%" }}
            />
          </div>
        </Slider>

        <div className="txt-wrap">
          <h2>What is your Life Value?</h2>
          <p>
            Top prices paid with prompt payment
            <br /> and free home collection
          </p>
          <div
            className="btn style01 md spc"
            onClick={() => (location.href = "/power")}
          >
            <span>Buy Powerball</span>
            <span className="material-symbols-rounded">chevron_right</span>
          </div>
        </div>
      </div>
    </>
  );
};

export default MagazineSlider;
