// tools
import { useState } from "react";
import User from "../../tools/userInfo";

// components
import Limit from "./limit";
import Notification from "./notification";
import PersonalInfo from "./personalInfo";
import ChangePassword from "./changePassword";
import PayMethod from "./payMethod";
import WithdrwalMethod from "./withdrawalMethod";
import Delete from "./delete";
import { CustModal } from "../common/commonElements";

const Settings = () => {
  const { info } = User();
  const [open, setOpen] = useState(false);
  const [content, setContent] = useState("");

  const handleClickOpen = (type: string) => {
    setContent(type);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const changeView = (type: string) => {
    switch (type) {
      case "personal":
        return <PersonalInfo setOpen={setOpen} />;
        break;

      case "password":
        return <ChangePassword setOpen={setOpen} />;
        break;

      case "paymethod":
        return <PayMethod setOpen={setOpen} />;
        break;

      case "withdrawalmethod":
        return <WithdrwalMethod setOpen={setOpen} />;
        break;

      case "limit":
        return <Limit setOpen={setOpen} />;
        break;

      case "notification":
        return <Notification setOpen={setOpen} />;
        break;
    }
  };

  return (
    <>
      <div className="setting">
        <div className="item">
          <h4 className="tit">Personal Information</h4>
          <p className="txt">
            View or edit your personal information. This will be used to contact
            you in the event of a win.
          </p>
          <div className="btn" onClick={() => handleClickOpen("personal")}>
            Edit
          </div>
        </div>

        {info.provider === "cluster" && (
          <div className="item">
            <h4 className="tit">Change Password</h4>
            <p className="txt">Change your Lottery Cluster account password.</p>
            <div className="btn" onClick={() => handleClickOpen("password")}>
              Change
            </div>
          </div>
        )}

        <div className="item">
          <h4 className="tit">Payment Methods</h4>
          <p className="txt">Manage Payment and Funds Methods</p>
          <div
            className="btn"
            onClick={() => {
              handleClickOpen("paymethod");
            }}
          >
            Edit
          </div>
        </div>

        <div className="item">
          <h4 className="tit">Withdrawal Methods</h4>
          <p className="txt">Manage Payment and Funds Methods</p>
          <div
            className="btn"
            onClick={() => {
              handleClickOpen("withdrawalmethod");
            }}
          >
            Edit
          </div>
        </div>

        <div className="item">
          <h4 className="tit">Weekly Spend Limit</h4>
          <p className="txt">
            Your current weekly spend limit is{" "}
            <span>${info.weeklySpendLimit.toLocaleString("au")}</span>{" "}
            <span>(Remaining: ${info.remaining.toLocaleString("au")} )</span>
          </p>
          <div className="btn" onClick={() => handleClickOpen("limit")}>
            Edit
          </div>
        </div>

        <div className="item">
          <h4 className="tit">Notification Settings</h4>
          <p className="txt">Edit your notification settings.</p>
          <div className="btn" onClick={() => handleClickOpen("notification")}>
            Edit
          </div>
        </div>

        <CustModal open={open} setOpen={setOpen} onClose={handleClose}>
          {changeView(content)}
        </CustModal>
        <Delete />
      </div>
    </>
  );
};

export default Settings;
